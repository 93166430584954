/* eslint-disable space-before-blocks */
/**
 * Created by jiachenpan on 16/11/18.
 */
import { getToken, getRefreshToken } from '@/utils/auth'
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
export function getCode(name, isDev){
  if(isDev){
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
  }else{
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }
  
}
export function checkPhone (phone) {
  let r = /^(0|86|17951)?(13[0-9]|14[01456789]|15[0-9]|16[56]|17[0-8]|18[0-9]|198|199)[0-9]{8}$/
  return r.test(phone)
}
export function isNull (data) {
  return (data == "" || typeof (data) == undefined || data == null) ? true : false;
}


export function formatTime(time, option) {
  time = +time * 1000
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

export function uuid() {
  var s = [];
  var hexDigits = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";
  var uuid = s.join("");
  return uuid;
}

// 接口封装
export default function interfaceHandle(inf_code, request_body, has_token){
 
  var req_time = parseTime((new Date()).getTime())
  var testflag = '0'
  var uid = uuid()

  var request_head ={
    "inf_code": inf_code, 
    "req_time": req_time, 
    "testflag": testflag, 
    "uid": uid
  }

  if (has_token){
    request_head['token'] = getToken()
    request_head['refreshtoken'] = getRefreshToken()
  }
 
  var interfaceObj = {
    "request_body": request_body,
    'request_head': request_head
  }


  return interfaceObj
}

export function emptyArray(list) {
    return !Array.isArray(list) || list.length === 0
}

// 基础列表数据
export const baseListData = {
    page: 1, // 页数
    limit: 10, // 每页数量
    loading: false, // 是否加载中
    over: false, // 是否有数据且加载完成
    noData: false, // 是否无数据
    total: 0, // 总数
    refresher: false, // 是否下拉刷新
    list: [], // 列表数据
};

/**
 * 更新列表数据
 * @param {*} params 请求参数
 * @param {*} params.type 处理类型 success:成功 fail:错误
 * @param {*} params.data 处理对象
 * @param {*} params.res 接口返回数据
 * @param {*} params.fn 对列表项处理函数
 * @returns
 */
export function updateListData({ type = "success", data, res, fn }) {
    if (type === "fail") {
        for (const key in baseListData) {
            data[key] = baseListData[key];
        }
        data.noData = true;
        data.total = 0;
        return;
    }

    if (res == null || !Array.isArray(res.list)) {
        updateListData({ type: "fail", data });
        return Promise.reject("获取数据异常!");
    }

    data.page++;
    data.loading = false;

    if (data.refresher) {
        data.refresher = false;
    }

    if (fn) {
        res.list = res.list.map(fn);
    }

    const total = res.total || res.num;
    if (total == null || total == 0 || res.list.length === 0) {
        data.noData = true;
        data.over = false;
        data.total = 0;
        return;
    }

    data.total = total;
    data.list = data.list.concat(res.list);

    if (data.list.length >= total) {
        data.over = true;
    }
}