export default {
    state: {
        exclude: [],
    },

    mutations: {
        addExclude(state, name) {
            if (!state.exclude.includes(name)) {
                state.exclude.push(name);
            }
        },

        removeExclude(state, name) {
            state.exclude = state.exclude.filter((item) => item !== name);
        },
    },
};
