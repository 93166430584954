export default {
    state: {
        // 导入病例数据
        importPrescriptionData: {
            status: 0, // 状态 0默认 1待导入
            doctor: null, // 开单医生
            complaint: null, // 主诉
            medicalhistory: null, // 病史
            pulsecondition: null, // 脉象
            tongue: null, // 舌象
            diagnose: null, // 中医诊断
            westerndiagnostics: null, // 西医诊断
        },

        // 导入处方数据
        importOrderData: {
            status: 0, // 状态 0默认 1待导入
            id: null, // 模板ID或订单ID
            type: null, // 查询类型
        },
    },

    mutations: {
        /**
         * 初始化导入病例数据
         */
        initImportPrescriptionData(state) {
            for (const key in state.importPrescriptionData) {
                state.importPrescriptionData[key] = null;
            }
            state.importPrescriptionData.status = 0;
        },
        /**
         * 设置导入病例数据
         */
        setImportPrescriptionData(state, data) {
            for (const key in state.importPrescriptionData) {
                state.importPrescriptionData[key] = data[key];
            }
            state.importPrescriptionData.status = 1;
        },

        /**
         * 初始化导入处方数据
         */
        initImportOrderData(state) {
            for (const key in state.importOrderData) {
                state.importOrderData[key] = null;
            }
            state.importOrderData.status = 0;
        },
        /**
         * 设置导入处方数据
         */
        setImportOrderData(state, data) {
            for (const key in state.importOrderData) {
                state.importOrderData[key] = data[key];
            }
            state.importOrderData.status = 1;
        },
    },
};
