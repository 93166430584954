// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from './router'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'vant/lib/button/style/less';
import '@/styles/index.scss'
import '@/styles/button.scss'
import '@/styles/iconfont/iconfont.css'
import 'lib-flexible/flexible.js'
import store from './store'
import { getToken } from '@/utils/auth'
import { getCode } from '@/utils'
import preventReClick from '@/utils/dbClick'
Vue.use(preventReClick)
import { Toast } from 'vant';
import App from './App.vue'
import { getClinicId, getName } from "./utils/auth";
import VueWechatTitle from "vue-wechat-title"
import { initWxJssdkFilter } from "./utils/common";
// import wx from 'weixin-js-sdk'
import dayjs from 'dayjs';

// Vue.use(wx)
Vue.use(VueWechatTitle)
Vue.use(Vant)

Vue.prototype.dayjs = dayjs;

Vue.config.productionTip = false
const isDevelopment = process.env.NODE_ENV === 'development'

let toastid = '';
if (isDevelopment) {
  router.beforeEach((to, from, next) => {
    let path = to.path
    console.log(path, 'path-----')
    if (path == '/my') {
      store.dispatch('changeNav', '1')
    }
    store.dispatch('Login').then(() => {
      // initWxJssdkFilter()
      // store.dispatch('qryCommonData')
      let clinicId = getClinicId();
      if (path != '/changeClinic' && (clinicId == null || clinicId == undefined)) {
        router.replace('/changeClinic')
      }
    }).then(() => {
      next()
    })
  })
} else {
  // 鉴权（暂时隐藏）
  router.beforeEach(function (to, from, next) {
    document.title = to.meta.title
    let path = to.path
    let fromPath = from.path
    /*alert(path + "----to")
    alert(fromPath + "----fromPath")
    alert(window.location.href + "----window.location.href")
    console.log(path,'to path-----')
    console.log(fromPath,'from path-----')
    if(getClinicId() != null && getClinicId() != '') {
      if(fromPath == '/' && (path == '/' || path == '/changeClinic' || path == '/bindLogin')) {
        wx.closeWindow()
      }
    }else if((fromPath == '/changeClinic' || fromPath == '/bindLogin') && path == '/') {
      wx.closeWindow()
    }*/
    var hasLogin = getToken()
    let beforeUrl = window.location.href;
    if (fromPath == '/' && path == '/' && beforeUrl.endsWith('/')) {
      if ((!getClinicId() || !getName()) && hasLogin) {
        initWxJssdkFilter()
        return false;
      }
    }
    if (path == '/my') {
      store.dispatch('changeNav', '1')
    }
    Toast('加载中...')
    console.log(router, '---缓存')
    if (hasLogin) {
      console.log('已经登录')
      // initWxJssdkFilter()
      next()
    } else {
      console.log('未登录')
      let pageUrl = window.location.href
      var url = ''
      if (process.env.NODE_ENV == 'development') {
        url = 'http://apartment.vipgz4.idcfengye.com?roleid=1'
      } else {
        url = encodeURIComponent(pageUrl)
      }
      var appid = process.env.VUE_APP_ID
      var urlWX = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + url + "&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect"
      var hasCode = getCode('code')
      console.log(hasCode, 'hasCode---')
      if (hasCode) {
        store.dispatch('Login').then(() => {
          if (store.getters.isbind === '0') {
            router.replace('/bindLogin')
          } else {
            let clinicId = getClinicId();
            if (path != '/changeClinic' && (clinicId == null || clinicId == undefined)) {
              router.replace('/changeClinic')
            }
          }
        }).then(() => {
          next()
        })
      } else {
        window.location.href = urlWX
      }
    }

  })
}

router.afterEach(function (to, from, next) {
  // var _this = this

  if (!to.meta.notload) {
    setTimeout(() => {
      Toast.clear();
    }, 200)
  }

})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  template: '<App/>'
})
